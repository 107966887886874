/**
 * Creates an operator function that unsubscribes from an observable when the component is destroyed.
 * @returns An operator function that can be used with the `pipe` method of an observable.
 */
import { DestroyRef, inject } from '@angular/core';
import { MonoTypeOperatorFunction, Subject, takeUntil } from 'rxjs';

export function untilDestroyed(): <T>() => MonoTypeOperatorFunction<T> {
  const subject = new Subject();

  inject(DestroyRef).onDestroy(() => {
    subject.next(true);
    subject.complete();
  });

  return <T>() => takeUntil<T>(subject.asObservable());
}
